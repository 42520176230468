@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.none {
    display: none;
}

._loading_overlay_wrapper {
    position: inherit !important;
}
._loading_overlay_overlay{
    background: #21252973 !important;
    z-index: 2147483647 !important;
}

.swal2-styled.swal2-confirm {
    background-color: #0d6efd !important;
}

.swal2-styled.swal2-confirm:focus {
-webkit-box-shadow: 0 0 0 3px #72a6f580 !important;
        box-shadow: 0 0 0 3px #72a6f580 !important;
}
  
.swal2-styled.swal2-cancel {
background-color: #9c9d9e !important;
}
  
.swal2-styled.swal2-cancel:focus {
-webkit-box-shadow: 0 0 0 3px #b2b5b780 !important;
        box-shadow: 0 0 0 3px #b2b5b780 !important;
}