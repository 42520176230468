.text-justify{
    text-align: justify;
   }

.section-codeLogin{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f1f4f9;
}

.contendor{
    position: relative;
    width: 400px;
    min-height: 400px;
    background: rgba(255,255,255,0.8);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 25px 45px rgba(0,0,0,0.1);
    border: 1px solid rgba(255,255,255,0.5);
    border-right: 1px solid rgba(255,255,255,0.2);
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin: 5rem;
}

.form {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 40px;

    h2 {
        position: relative;
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 40px;
    }

    h2::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 80px;
        height: 4px;
        background: #214c9a;
    }

    .codeNotification {
        min-height: 50px;
    }

    .inputBox {
        width: auto;
        margin-top: 20px;

        .input {
            width: 100%;
            background: rgba(255,255,255,0.9);
            border: none;
            outline: none;
            padding: 10px 20px;
            border-radius: 10px;
            border: 1px solid rgba(241,244,249,0.9);
            border-right: 1px solid rgba(241,244,249,0.2);
            border-bottom: 1px solid rgba(241,244,249,0.2);
            font-size: 16px;
            letter-spacing: 1px;
            color: #333;
            box-shadow: 0 5px 15px rgba(0,0,0,0.05);
        }

        .input::placeholder {
            color:#214c9a;
        }
    }

    .lastInputBox {
        margin-bottom: 40px;
    }

    .inputOTPBox {
        width: auto;
        margin-top: 20px;

        .containerInput {
            align-items: center;
        }

        .input {
            width: 100%!important;
            max-width: 3em;
            min-width: 3em;
            background: rgba(255,255,255,0.9);
            outline: none;
            padding: 10px 1em 10px 1em;
            margin: 0 2px 0 2px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0,0.4);
            font-size: 16px;
            letter-spacing: 1px;
            color: #333;
            box-shadow: 0 5px 15px rgba(0,0,0,0.05);
        }

        .input::placeholder {
            color:#214c9a;
        }

        input:not([value]), input[value=""] {
            border: 1px solid #dc3545; /* Red border only if the input is empty */
        }

        .focus {
            background: rgba(180, 178, 178, 0.9);
        }
    }
}

.field-error{
    padding-left: 0.4rem;
}

.continue-code-login{
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 10px;
    height: 70%;
    min-height: 45px;
}

.continue-code-login:hover{
    background: rgba(233,30,99,1) !important;
    color:#fff !important;
}

.close-code-login{
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 10px;
    height: 70%;
}

.close-code-login:hover{
    background: rgba(233,30,99,1) !important;
    color:#fff !important;
}

.submit-code-login{
    width: 70%;
    height: 70%;
    background: rgba(233,30,99,1) !important;
    color:#fff !important;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 10px;
    font-weight: 600;
    border-radius: 10px;
	border: 1px rgba(233,30,99,1) solid !important;
    padding: 10px 20px;
}