.section-loginAfiliado {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f1f4f9;
}
.section-loginAfiliado .containerAfiliado {
  position: relative;
  width: 400px;
  min-height: 400px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.section-loginAfiliado .divTimerAfiliado {
  text-align: -webkit-center;
}
.section-loginAfiliado .formAfiliado {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px;
}
.section-loginAfiliado .formAfiliado .h2Img {
  position: relative;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 40px;
}
.section-loginAfiliado .formAfiliado .h2Img::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 80px;
  height: 4px;
  background: #214c9a;
}
.section-loginAfiliado .formAfiliado .inputComponent {
  width: auto;
  margin-top: 20px;
}
.section-loginAfiliado .formAfiliado .inputComponent .input {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid rgba(241, 244, 249, 0.9);
  border-right: 1px solid rgba(241, 244, 249, 0.2);
  border-bottom: 1px solid rgba(241, 244, 249, 0.2);
  font-size: 16px;
  letter-spacing: 1px;
  color: #333;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
.section-loginAfiliado .formAfiliado .inputComponent .input::-moz-placeholder {
  color: #214c9a;
}
.section-loginAfiliado .formAfiliado .inputComponent .input::placeholder {
  color: #214c9a;
}
.section-loginAfiliado .formAfiliado .inputComponent .select {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid rgba(241, 244, 249, 0.5);
  border-right: 1px solid rgba(241, 244, 249, 0.2);
  border-bottom: 1px solid rgba(241, 244, 249, 0.2);
  font-size: 16px;
  letter-spacing: 1px;
  color: #214c9a;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  cursor: pointer !important;
}
.section-loginAfiliado .formAfiliado .inputComponent .option {
  color: #333;
}
.section-loginAfiliado .field-error {
  padding-left: 0.4rem;
}
.section-loginAfiliado .submit-login {
  background: rgb(233, 30, 99) !important;
  color: #fff !important;
  max-width: 160px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: 600;
  border: 1px rgb(233, 30, 99) solid !important;
}
.section-loginAfiliado .envio-login {
  background: rgb(233, 30, 99);
  color: #fff !important;
  max-width: 160px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: 600;
  border: 1px rgb(233, 30, 99) solid;
}

::-webkit-scrollbar {
  width: 4px;
}/*# sourceMappingURL=loginScreemAfiliado.css.map */