.landing-container {
  margin: 0;
  font-family: Arial, sans-serif;
  position: relative;
  min-height: 600px;
  overflow: hidden;
}
.landing-container .landing-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.landing-container .landing-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 130%;
  height: 100%;
  display: flex;
}
.landing-container .landing-left {
  flex: 1;
  background-color: #003cff;
  z-index: 1;
}
.landing-container .landing-right {
  flex: 1;
  background-color: #ffffff;
  z-index: 1;
}
.landing-container .landing-content {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.landing-container .landing-title {
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  position: absolute;
  top: 48%;
  left: 5%;
  line-height: 0.5;
  text-align: start;
  z-index: 3;
}
.landing-container .landing-title p {
  font-size: 2.2rem;
  font-weight: bold;
}
.landing-container .landing-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  left: 66%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.landing-container .landing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  padding: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.landing-container .landing-card:hover {
  transform: scale(1.05);
}
.landing-container .landing-logo {
  width: 230px;
  height: 80px;
  object-fit: contain;
}
.landing-container .landing-description {
  color: #333;
  font-size: 16px;
  text-align: center;
  margin: 0;
}
.tooltip-container {
  position: relative;
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.landing-container .logo-landing{
  margin: 30px 0 0 70px;
  border-radius: 10px;
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .landing-container {
    min-height: auto;
    padding: 20px;
    text-align: center;
  }
  .landing-container .logo-landing {
    margin: 15px;
    right: 76px;
    width: 138px;
  }
  .landing-container .landing-title {
    font-size: 1.5rem;
    top: 100px;
    left: 47px;
    line-height: 1;
    color: #2c3e50;
  }
  .landing-container .landing-title p {
    font-size: 1.4rem;
    margin: 5px 0;
  }
  .landing-container .landing-options {
    flex-direction: column;
    gap: 0px;
    position: relative;
    left: auto;
    top: auto;
    transform: none;
  }
  .landing-container .landing-card {
    max-width: 100%;
    padding: 15px;
  }
  .landing-container .landing-background {
    width: 105%;
    height: 100%;
  }
  .landing-container .landing-content {
    justify-content: end;
  }

}/*# sourceMappingURL=LandingPageAfiliado.css.map */