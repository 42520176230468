.loaderIP4-solid {
  z-index: 999999999;
  position: fixed;
  width: 100%;
  height: 100%;
}
.loaderIP4-solid .text-loader {
  color: white;
}

.loaderIP4 {
  opacity: 0.4;
}
.loaderIP4 .text-loader {
  color: white;
}

.loaderIP4-solid.loaderIP4 {
  opacity: 1;
}

.loaderIP4 .page-loader,
.loaderIP4-solid .page-loader {
  z-index: 1;
  display: table;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #214c9a !important;
}

.loaderIP4 .page-loader .page-loader-content,
.loaderIP4-solid .page-loader .page-loader-content {
  display: table-cell;
  vertical-align: middle;
}

.loaderIP4 .lds-ellipsis,
.loaderIP4-solid .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.loaderIP4 .lds-ellipsis div:nth-child(1),
.loaderIP4-solid .lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.loaderIP4 .lds-ellipsis div:nth-child(2),
.loaderIP4-solid .lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loaderIP4 .lds-ellipsis div:nth-child(3),
.loaderIP4-solid .lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loaderIP4 .lds-ellipsis div:nth-child(4),
.loaderIP4-solid .lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

.loaderIP4 .lds-ellipsis div,
.loaderIP4-solid .lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}/*# sourceMappingURL=loaderIP.css.map */