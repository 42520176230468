/* PasswordField.css */
.inputBox {
  margin-bottom: 1rem;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
  padding-right: 2rem;
}

.password-toggle {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #3b61a5;
}

.field-error {
  margin-top: 5px;
  font-size: 0.875rem;
}
