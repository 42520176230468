.text-justify {
  text-align: justify;
}

.section-changepass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  background: #f1f4f9;
}

.contendor {
  position: relative;
  width: 500px;
  min-height: 400px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
          box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin: 5rem;
}

.form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.form h2 {
  position: relative;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 40px;
}

.form h2::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 80px;
  height: 4px;
  background: #214c9a;
}

.form .inputBox {
  width: auto;
  margin-top: 20px;
}

.form .inputBox .input {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid rgba(241, 244, 249, 0.9);
  border-right: 1px solid rgba(241, 244, 249, 0.2);
  border-bottom: 1px solid rgba(241, 244, 249, 0.2);
  font-size: 16px;
  letter-spacing: 1px;
  color: #333;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.form .inputBox .input::-webkit-input-placeholder {
  color: #214c9a;
}

.form .inputBox .input:-ms-input-placeholder {
  color: #214c9a;
}

.form .inputBox .input::-ms-input-placeholder {
  color: #214c9a;
}

.form .inputBox .input::placeholder {
  color: #214c9a;
}

.field-error {
  padding-left: 0.4rem;
}

.close-change-pass-mand {
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 10px;
  height: 70%;
}

.close-change-pass-mand:hover {
  background: #e91e63 !important;
  color: #fff !important;
}

.submit-change-pass-mand {
  width: 70%;
  height: 70%;
  background: #e91e63 !important;
  color: #fff !important;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 10px;
  font-weight: 600;
  border-radius: 10px;
  border: 1px #e91e63 solid !important;
  padding: 10px 20px;
}
