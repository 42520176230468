/* Tooltip.css */
.tooltip-container {
    margin-top: 0.7em;
    margin-left:  -2em;
    position: absolute;
    display: inline-block;
  }
  
  .tooltip-icon {
    cursor: pointer;
    user-select: none;
    width: 23px; /* Ancho deseado */
    height: 22px; /* Alto deseado */
  }
  
  .tooltip-text {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-60%);
    padding: 13px;
    background-color: #333;
    color: #fff;
    border-radius: 3px;
    opacity: 0.9;
    font-size: 12px;
    display: none;
    z-index: 1;
    width: 14em;
  }
  
  .tooltip-container:hover .tooltip-text {
    display: block;
  }
  